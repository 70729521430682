<template lang="pug">
.container
  .row
    .col-md-9
      .text-center.bg-primary.py-3.jr-rounded-top(
        style="b"
      )
        h4.text-light.m-0 規律訂課
      .schedule-card-box.border-0.p-3.jr-rounded-bottom
        full-calendar(ref="calendar" :options="calendarOptions")
          template(v-slot:eventContent="arg")
            .d-flex.justify-content-center {{ arg.event.title }}
        .d-flex.justify-content-end.align-items-center.mt-3
          div
            b-button.mr-1(
              class="submit-btn"
              variant="primary"
              @click="showSaveConfirm"
              :disabled="(events.added.length + events.deleted.length) <= 0"
            ) 送出
    .col-md-3.warning-card
      .text-center.bg-primary.py-3.jr-rounded-top
        h4.text-light.m-0 規律須知
      .py-3.px-2.jr-rounded-bottom.regulations-box
        b-alert.border-0(
          v-for='(data,index) in note' :key='index'
          variant='secondary'
          show
        )
          span.content(v-html="data.content")
</template>

<script>
/* eslint-disable no-unused-vars */
import FullCalendar from '@fullcalendar/vue';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import TimeGridPlugin from '@fullcalendar/timegrid';

import swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';

import WuwowLoadingMixin from '@/components/wuwowLoading/mixin/index.vue';

import { uniqueId, chain } from 'lodash';
import moment from 'moment';

import {
  getColorAndTitle,
  getRestTime,
  isTimeRangeInRestTime,
  isPastOrCurrent,
  STATUS,
} from '@/utils/schedule';
import api from '@lioshutan/api-package';

import platform from '@/constants/platform';

const EMPTY_EVENT_TITLE = '__';
export default {
  components: {
    FullCalendar,
  },

  mixins: [WuwowLoadingMixin],

  data() {
    return {
      note: [
        {
          content:
            '此功能可為您自動安排未來固定的【一般課程】上課時段，系統將於每日晚間20:50更新。',
        },
        {
          content:
            '如20:50前設定，您的設定將於隔日生效;<br>如超過20:50後設定，您的設定將於兩日後生效。',
        },
        {
          content: '※ 系統執行時間：每晚20:50由系統預約隔日課程，如需取消請至訂課紀錄。',
        },
        {
          content: '※規律訂課僅限一般課程，無法指定顧問或自備教材。',
        },
      ],
      modal: {
        repitition: false,
      },
      regularSchedule: {
        days: 1,
        weeks: 1,
        show: false,
      },
      calendarApi: null,
      start: null,
      end: null,
      token: '',
      now: '',
      // All of Moment objects should be formatted to this format.
      timeFormat: 'YYYY-MM-DD HH:mm',
      // The forbidden area displayed in the calendar.
      forbid: {
        title: 'NO CLASS',
        start: '00:30',
        end: '09:00',
        rendering: 'background',
        overlap: false,
        color: '#596368',
      },
      events: {
        added: [],
        deleted: [],
      },
      calendarOptions: {
        editable: false,
        dayHeaderFormat: {
          weekday: 'short',
        },
        /**
         * DateTimeFormatOptions
         */
        slotLabelFormat: [
          {
            hour: '2-digit',
            minute: '2-digit',
            omitZeroMinute: false,
            meridiem: 'long',
            hour12: false,
          },
        ],
        // initialDate: new Date(2021, 11, 26),
        // visibleRange: {
        //   start: new Date(2021, 12, 26),
        //   end: new Date(2022, 1, 1),
        // },
        slotDuration: '00:30:01',
        plugins: [dayGridPlugin, interactionPlugin, TimeGridPlugin],
        allDaySlot: false,
        headerToolbar: false,
        // headerToolbar: {
        //   left: 'prev,next',
        //   center: 'title',
        //   right: 'dayGridMonth,timeGridWeek,timeGridDay,today',
        // },
        selectOverlap: false,
        initialView: 'timeGridWeek',
        locale: 'zh-tw',
        droppable: false,
        selectable: true,
        select: this.handleDateSelect,
        eventAdd: this.eventAdd,

        events: this.handleEvents,
        // https://fullcalendar.io/docs/eventClick < eventClick文件
        eventClick: this.handleEventClick,
        // eventsSet: this.handleEvents,
        eventChange: this.eventChange,
        eventRemove: this.eventRemove,
        /* you can update a remote database when these fire:
         */
      },
      currentEvents: [],
    };
  },
  async created() {
    this.token = this.$store.state.auth.token;
  },
  mounted() {
    this.calendarApi = this.$refs['calendar']['getApi']();
    // if (this.$route.query['date']) {
    //   this.$refs['calendar']['getApi']()['gotoDate'](this.$route.query['date']);
    // }
  },
  methods: {
    changeDate(dateStr) {
      return new Date(dateStr);
    },
    disabledWorkTime(disabled, dateObj) {
      const { Day, Hours, Min, Sec } = disabled;
      dateObj.setDate(Day);
      dateObj.setHours(Hours);
      dateObj.setMinutes(Min);
      dateObj.setSeconds(Sec);
      return dateObj;
    },
    isWeekday(day) {
      const week = moment(day).weekday();
      const weekdays = [0, 6];
      if (weekdays.includes(week)) {
        return true;
      }
      return false;
    },
    refreshSchedule() {
      // TODO: 這邊要做重新刷新行事曆的動作
      location.reload();
    },
    async updateFixedSchedule(add, del) {
      try {
        const tag = this.startLoading();
        // /v3/taichi-external/student/vip-shift-config
        const response = await api
          .setDNS(process.env.VUE_APP_BASE_API)
          .vip(this.token)
          .setStudentFixedSchedule(add, del);
        this.refreshSchedule();
        this.endLoading(tag);
      } catch (error) {
        const errorCode = 'dc2e4270-89b6-44c2-aed5-d68a54b0b458';
        await swal.fire('錯誤', `看起來有些問題呢！\n錯誤代碼：${errorCode}`, 'error');
      }
    },
    /**
     * @param {array} events
     */
    getAddedParams(events) {
      const params = [];
      for (let i = 0; i < events.length; i++) {
        const target = events[i];
        if (target.id) {
          let start = moment(target.start);
          const end = moment(target.end);
          while (!start.isSame(end)) {
            let timestamp = start.format('YYYYMMDD');
            let timeslot =
              moment(start.format('YYYY-MM-DD HH:mm')).subtract(9, 'hour').get('hour') *
              2;

            timeslot = start.format('mm') === '00' ? timeslot : timeslot + 1;
            timeslot++;

            timestamp =
              timeslot === 31
                ? moment(timestamp, 'YYYYMMDD').subtract(1, 'day').format('YYYYMMDD')
                : timestamp;
            timestamp += timeslot > 9 ? timeslot : '0' + timeslot;
            params.push({
              timestamp: parseInt(timestamp, 10),
              timeslot: timeslot,
            });
            start = moment(start).add(30, 'minutes');
          }
        }
      }
      return params;
    },
    async showSaveConfirm() {
      try {
        await swal.fire({
          title: '異動確認',
          text: '確認是否要做行程異動?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '送出',
          cancelButtonText: '取消',
        }).then((result) => {
          if (!result.isDismissed){
            const add = this.getAddedParams(this.events.added);
            // const deleted = this.events.deleted[0] === '' ? [] : this.events.deleted;
            const deleted = chain(this.events.deleted).filter(item => item !== '').value();
            this.updateFixedSchedule(add, deleted);
          }
        });
      } catch (error) {
        const errorCode = '240fc077-b5c8-4dc4-8033-c8132af54331';
        await swal.fire('錯誤', `看起來有些問題呢！\n錯誤代碼：${errorCode}`, 'error');
      }
    },
    /**
     * 抓取所有行程
     */
    async handleEvents({ start, end }, success, fail) {
      this.start = start;
      this.end = end;

      try {
        const response = await this.getStudentFixeSchedule({
          start,
          end,
        });
        const wholeWeekTimestamp = this.getWholeWeekTimestamp(start, end);
        const emptyEvents = this.getEmptyDate(wholeWeekTimestamp, response);
        const allEvent = emptyEvents.concat(response);
        success(allEvent);
      } catch (error) {
        const errorCode = '9187afd6-be84-4745-8886-534342dfa12d';
        await swal.fire('錯誤', `看起來有些問題呢！\n錯誤代碼：${errorCode}`, 'error');
        fail(error);
      }
    },

    /**
     * 取得顧問排程
     *
     * @returns {Promise<Array>} 顧問排成
     */
    async getStudentFixeSchedule({ start, end }) {
      const tag = this.startLoading();
      const days = (end - start) / (1000 * 60 * 60 * 24);
      const daysArray = Array(days).fill();
      const formatOption = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      };
      const startStr = start.toLocaleString('tw', formatOption);
      const saturdayIndex = daysArray.length - 1;
      const sundayIndex = 0;

      this.id = this.$route.params['id'];

      const response = await api
        .setDNS(process.env.VUE_APP_BASE_API)
        .vip(this.token)
        .getStudentFixedSchedule(platform.WUWOW_JUNIOR);

      const result = response.data.map(({ id, status, timeslot, timestamp, type }) => {
        const todayMoment = moment();
        const firstDay = todayMoment.add(-todayMoment.weekday(), 'days');
        const startFromServer = moment(
          `${timestamp.toString().substring(0, 8)}0830`,
          'YYYY-MM-DD HH:mm'
        ).add(timeslot * 30, 'minute');
        const start = moment(
          `${firstDay
            .add(startFromServer.weekday(), 'days')
            .format('YYYY-MM-DD')} ${startFromServer.format('HH:mm:ss')}`
        );
        const end = moment(start.format('YYYY-MM-DD HH:mm')).add(30, 'minute');
        const result = {
          id,
          start: start.toISOString(),
          end: end.toISOString(),
          status,
          type: +type,
        };

        const colorAndTitle = {
          color: '#2D882D',
          title: 'Class',
        };

        return {
          backgroundColor: colorAndTitle.color,
          borderColor: colorAndTitle.color,
          title: colorAndTitle.title,
          ...result,
        };
      });

      const restTime1 = ((start, end) => {
        return daysArray
          .map((day, index) => {
            const startTime = this.changeDate(startStr);
            const endTime = this.changeDate(startStr);
            const defaultDisabledTime = {
              // 00:00:00 ~ 12:30:00
              start: {
                Day: startTime.getDate() + index,
                Hours: 0,
                Min: 0,
                Sec: 0,
              },
              end: {
                Day: endTime.getDate() + index,
                Hours: 12,
                Min: 30,
                Sec: 0,
              },
            };
            if ([saturdayIndex, sundayIndex].includes(index)) {
              defaultDisabledTime.end.Hours = 9;
              defaultDisabledTime.end.Min = 0;
            }
            const data = {
              start: this.disabledWorkTime(defaultDisabledTime.start, startTime),
              end: this.disabledWorkTime(defaultDisabledTime.end, endTime),
            };
            return data;
          });
      })(start, end).map((time) => ({
        ...time,
        display: 'background',
        color: '#596368',
        classNames: ['not-allow-click'],
      }));

      const restTime2 = ((start, end) => {
        return daysArray
          .map((day, index) => {
            const startTime = this.changeDate(startStr);
            const endTime = this.changeDate(startStr);
            const defaultDisabledTime = {
              // 21:30:00 ~ 23:59:59
              start: {
                Day: startTime.getDate() + index,
                Hours: 21,
                Min: 30,
                Sec: 0,
              },
              end: {
                Day: endTime.getDate() + index,
                Hours: 23,
                Min: 59,
                Sec: 59,
              },
            };
            const data = {
              start: this.disabledWorkTime(defaultDisabledTime.start, startTime),
              end: this.disabledWorkTime(defaultDisabledTime.end, endTime),
            };

            return data;
          });
      })(start, end).map((time) => ({
        ...time,
        display: 'background',
        color: '#596368',
      }));

      result.push(...restTime1, ...restTime2);

      this.endLoading(tag);

      return result;
    },

    /**
     * 針對未排定的部分動作
     */
    handleDateSelect({ start, end, startStr, endStr }) {
      const openTime = ['12:30:01', '21:29:59'];
      if (this.isWeekday(start)) {
        openTime[0] = '09:00:01';
      }
      const disableTime = [
        moment(start).format('YYYY-MM-DD HH:mm:ss'),
        moment(end).format('YYYY-MM-DD HH:mm:ss'),
      ];
      const isInWorkTime = disableTime
        .map((date) =>
          moment(date).isBetween(
            `${moment(start).format('YYYY-MM-DD')} ${openTime[0]}`,
            `${moment(start).format('YYYY-MM-DD')} ${openTime[1]}`
          )
        )
        .reduce((previous, status) => previous || status, false);

      if (moment(end).diff(start, 'minute') > 30) {
        return;
      }

      if (!isInWorkTime) {
        alert('您無法在休息時間排課！');
        return;
      }
      const title = '等待送出';
      const calendarApi = this.calendarApi;

      calendarApi.unselect(); // clear date selection
      const id = uniqueId('unsaved');

      calendarApi.addEvent({
        id,
        title,
        start: startStr,
        end: endStr,
        // allDay: selectInfo.allDay,
      });
    },

    /**
     * 針對Event動作
     */
    async handleEventClick({
      el,
      event,
      // {
      //   id,
      //   groupId,
      //   title,
      //   display,
      //   extendedProps: {
      //     status,
      //     type,
      //     cusInfo,
      //   },
      //   start,
      //   end,
      //   remove,
      // },
      jsEvent,
      view,
    }) {
      const openTime = ['12:30:01', '21:29:59'];
      if (this.isWeekday(event.start)) {
        openTime[0] = '09:00:01';
      }
      const disableTime = [
        moment(event.start).format('YYYY-MM-DD HH:mm:ss'),
        moment(event.end).format('YYYY-MM-DD HH:mm:ss'),
      ];
      const isInWorkTime = disableTime
        .map((date) =>
          moment(date).isBetween(
            `${moment(event.start).format('YYYY-MM-DD')} ${openTime[0]}`,
            `${moment(event.start).format('YYYY-MM-DD')} ${openTime[1]}`
          )
        )
        .reduce((previous, status) => previous || status, false);
      // https://fullcalendar.io/docs/event-object < Event物件文件
      // console.log([event.id, event, event.backgroundColor, event.borderColor]);
      // event.backgroundColor = '#111111';
      // console.log([event.id, event.groupId, event.title, event.start, event.end, event.extendedProps]);
      if (!isInWorkTime) {
        return;
      }

      event.remove();

      if (!event.id.includes('unsaved')) {
        this.events.deleted.push(event.id);
        return;
      }
    },
    /**
     * https://fullcalendar.io/docs/eventAdd
     */
    eventAdd({ event, relatedEvents, revert }) {
      this.events.added.push({
        id: event.id,
        title: event.title,
        start: event.startStr,
        end: event.endStr,
      });
    },
    /**
     * https://fullcalendar.io/docs/eventRemove
     */
    eventRemove({ event, relatedEvents, revert }) {
      if (event.title === EMPTY_EVENT_TITLE) {
        this.handleDateSelect(event);
      }
      this.events.added = this.events.added.filter((add) => add.id !== event.id);
    },

    eventChange(data) {
      // TODO: 還不知道能拿來做什麼
    },

    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends; // update a property
    },

    getWholeWeekTimestamp(start, end) {
      const dateArray = [];

      let startD = new Date(start);
      const endD = new Date(end);

      while (startD < endD) {
        dateArray.push(moment(startD).format('YYYY-MM-DD'));
        const newDate = startD.setDate(startD.getDate() + 1);
        startD = new Date(newDate);
      }
      let allScheduleTime = [];
      dateArray.forEach((date) => {
        allScheduleTime = allScheduleTime.concat(this.getCanBookTimePeroid(date));
      });
      return allScheduleTime;
    },

    getCanBookTimePeroid(date) {
      const startDate = moment(date).startOf('day');

      const dates = [];
      const hourDivider = 2;
      let endTimeIndex = hourDivider * 24;

      while (endTimeIndex-- > 5) {
        dates.push(startDate.toISOString());
        startDate.add(60 / hourDivider, 'minute');
      }

      // answer to second question
      let fromIndex = 24; // 12:30am
      if (this.isWeekday(new Date(date))) {
        fromIndex = 17; // 9:00am
      }
      const toDates = dates.slice(fromIndex + 1);
      return toDates;
    },

    getEmptyDate(all, events) {
      const emptyEvent = [];
      const deleteEvents = events.map((event) => {
        return event.start;
      });
      deleteEvents.forEach((event) => {
        let allIndex = 0;
        all.forEach((date) => {
          if (date === event) {
            all.splice(allIndex, 1);
          }
          allIndex++;
        });
      });
      all.forEach((start) => {
        const event = {
          start: start,
          end: moment(start).add(1800, 'seconds').toISOString(),
          title: EMPTY_EVENT_TITLE,
          color: '#FFFFFF',
        };
        if (moment(start).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
          event.color = '#FDFBD9';
        }
        emptyEvent.push(event);
      });
      return emptyEvent;
    },
  },
};
</script>

<style scoped>
.calendar-wrapper {
  display: inline-flex;
}
.schedule-card-box {
  background-color: #ffffff;
}
.regulations-box {
  background-color: #ffffff;
}
/* 隱藏event的點 拿掉就知道我在說啥了*/
::v-deep .fc-daygrid-event-dot {
  display: none;
}

::v-deep .overtime {
  background: #959595;
  border-color: #959595;
}

::v-deep .fc-toolbar-title {
  margin-right: 100px;
}

::v-deep .fc .fc-button {
  padding-top: 3px;
}

::v-deep .event-box {
  cursor: pointer;
  padding-left: 2px;
  transition: all 0.1s ease-out;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

::v-deep .event-box:hover {
  text-shadow: none;
}

::v-deep .event-moring {
  background: #ffc200;
  border-color: #ffc200;
}

::v-deep .event-afternoon {
  background: #f05a37;
  border-color: #f05a37;
}

::v-deep .event-evening {
  background: #0d6fd8;
  border-color: #0d6fd8;
}

::v-deep .overtime {
  background: #959595;
  border-color: #959595;
}

::v-deep .overtime:hover {
  cursor: not-allowed;
}

::v-deep .fc-event {
  display: flex;
}

::v-deep table ::-webkit-scrollbar {
  display: none;
}

::v-deep .fc-timegrid-event-harness {
  margin: 2px auto;
  padding-bottom: 1px;
}

::v-deep .fc-event-main > div {
  position: relative;
  top: -3px;
}
@media screen and (max-width: 768px) {
  ::v-deep .fc-media-screen {
    height: 350px;
  }
  ::v-deep .fc-timegrid {
    height: 350px;
  }
  .warning-card {
    margin-top: 30px;
  }
  .schedule-card-box {
    padding: 0 !important;
    height: 430px;
  }
  .submit-btn {
    font-size: 12px;
    padding: 10px;
    margin: 5px auto;
  }
}
</style>
