var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-9"
  }, [_vm._m(0), _c('div', {
    staticClass: "schedule-card-box border-0 p-3 jr-rounded-bottom"
  }, [_c('full-calendar', {
    ref: "calendar",
    attrs: {
      "options": _vm.calendarOptions
    },
    scopedSlots: _vm._u([{
      key: "eventContent",
      fn: function (arg) {
        return [_c('div', {
          staticClass: "d-flex justify-content-center"
        }, [_vm._v(_vm._s(arg.event.title))])];
      }
    }])
  }), _c('div', {
    staticClass: "d-flex justify-content-end align-items-center mt-3"
  }, [_c('div', [_c('b-button', {
    staticClass: "mr-1 submit-btn",
    attrs: {
      "variant": "primary",
      "disabled": _vm.events.added.length + _vm.events.deleted.length <= 0
    },
    on: {
      "click": _vm.showSaveConfirm
    }
  }, [_vm._v("送出")])], 1)])], 1)]), _c('div', {
    staticClass: "col-md-3 warning-card"
  }, [_vm._m(1), _c('div', {
    staticClass: "py-3 px-2 jr-rounded-bottom regulations-box"
  }, _vm._l(_vm.note, function (data, index) {
    return _c('b-alert', {
      key: index,
      staticClass: "border-0",
      attrs: {
        "variant": "secondary",
        "show": ""
      }
    }, [_c('span', {
      staticClass: "content",
      domProps: {
        "innerHTML": _vm._s(data.content)
      }
    })]);
  }), 1)])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center bg-primary py-3 jr-rounded-top",
    staticStyle: {}
  }, [_c('h4', {
    staticClass: "text-light m-0"
  }, [_vm._v("規律訂課")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center bg-primary py-3 jr-rounded-top"
  }, [_c('h4', {
    staticClass: "text-light m-0"
  }, [_vm._v("規律須知")])]);

}]

export { render, staticRenderFns }